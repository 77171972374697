import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, QueryList, ViewChildren, WritableSignal, signal } from '@angular/core';
import { MatOption, MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { APIResponse } from '@core/helpers/types';
import { TruncatedTextComponent } from '@shared/components/ui';
import { EncodingConfigViewDto } from '@shared/modules/encoding-config/types/encoding-config';
import * as uuid from 'uuid';

@Component({
  selector: 'green-option-group',
  standalone: true,
  imports: [ 
    MatOptionModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    NgIf,
    NgFor,
    TruncatedTextComponent
  ],
  templateUrl: './option-group.component.html',
})
export class OptionGroupComponent {
  @ViewChildren('option') options: QueryList<MatOption>;

  @Input() unlimited = '';
  @Input() groups: any[] = []
  @Input() multiple = false;

  @Input() set encodingConfing(config: APIResponse<EncodingConfigViewDto[]>) {
    if (!config) {
      return;
    }
  
    this.groups = this.getGroups(config.data);
  }

  @Input() set selected(selectedElements: any[]) {
    this.selectedElements.set(selectedElements);
  };

  @Output() selectedOptions = new EventEmitter<string | string[]>();

  public isExpanded = signal<{[name: string]: boolean}>({});
  public selectedElements = signal<any[]>([]);
  
  private _prevSelectedOption !: WritableSignal<MatOption | null>;

  // Set menu tree
  private getGroups(data: EncodingConfigViewDto[]) {
    const groups: any[] = [];
      
    data.forEach(row => {
      const labels = row.chemin.split('/');
      const lastLabel = labels[labels.length - 1];
      const firstLabel = labels[0];

      let group = this.getGroup(groups, firstLabel);

      labels.forEach(label => {

        if (group.label === label) {
          this.setOption(group, label, lastLabel, row.config);
        }

        else {

          if (!group.groups) {
            group.groups = [];
          }

          group = this.getGroup(group.groups, label);
          this.setOption(group, label, lastLabel, row.config);
        }
      })
    });

    return groups;
  }
  
  private setOption(group: any, label: string, lastLabel: string, options: any) {
    if (label === lastLabel) {

      if (!group.options) {
        group.options = [options];
      }

      else {
        group.options.push(options);
      }
    }
  }
  
  private getGroup(groups: any[], label: string) {
    let group = groups.find(g => g.label === label);

    if (!group) {
      group = {label}
      groups.push(group);
    }

    return group;
  }

  // Menu interactions
  moveMenu(menu: HTMLElement, bottom: number, bodyHeight: number) {
      const diff = bodyHeight - bottom - 10;
      menu?.style.setProperty('position', 'absolute');
      menu?.style.setProperty('top', diff + 'px');
    }

  manageOptions() {
    setTimeout(() => {
      const options = document.getElementsByClassName('option');
      
      // deselect options removed from outside this select
      this.options.forEach(option => {
        if (!this.selectedElements().map(e => e.name).includes(option.value)) {
          option.deselect();
        }
      });

      const optionBottom = options.item(options.length - 1)?.getBoundingClientRect().bottom;
      const menus = document.getElementsByClassName('cdk-overlay-pane');
      const lastMenu = menus.item(menus.length - 1) as HTMLElement;
      const menuBottom = lastMenu.getBoundingClientRect().bottom;
      const bodyHeight = document.body.clientHeight;

      if (optionBottom && (optionBottom > bodyHeight)) {
        this.moveMenu(lastMenu, optionBottom, bodyHeight);
      }

      else if (menuBottom && (menuBottom > bodyHeight)) {
        this.moveMenu(lastMenu, menuBottom, bodyHeight);
      }
      
    }, 90);
  }

  toggleExpand(label: string) {
    this.isExpanded()[label] = !this.isExpanded()[label];
  }

  // Select values
  selectUnlimitedOptions(option: MatOption, element: any) {
    option.deselect();
    const newElement = {...element};
    newElement.optionUuid = uuid.v4();
    this.selectedElements().push(newElement);
    this.selectedOptions.emit(this.selectedElements());
  }

  selectMultipleOptions(option: MatOption, element: any) {

    if (this.selectedElements().find(e => e.name === option.value)) {
      option.deselect();
      this.selectedElements.set(this.selectedElements().filter(e => e.name !== option.value));
    }

    else {
      element.optionUuid = uuid.v4();
      this.selectedElements().push(element);
    }

    this.selectedOptions.emit(this.selectedElements());
  }

  selectUniqueOption(option: MatOption, element: any) {

    if (this._prevSelectedOption) {
      this._prevSelectedOption()?.deselect();
    }

    this.selectedOptions.emit(element);

    if (!this._prevSelectedOption) {
      this._prevSelectedOption = signal(option);
    }

    else if (this._prevSelectedOption()?.value !== option.value) {
      this._prevSelectedOption.set(option);
    }

    else {
      this._prevSelectedOption.set(null);
    }
  }

  selectOption(option: MatOption, element: any, label: string, event: MouseEvent) {

    if (this.unlimited.toLocaleLowerCase() === label.toLocaleLowerCase()) {
      this.selectUnlimitedOptions(option, element);
      // prevent the menu from closing
      event.stopPropagation();
    }

    else if (this.multiple) {
      this.selectMultipleOptions(option, element);
      // prevent the menu from closing
      event.stopPropagation();
    }

    else {
      this.toggleExpand(label);
      this.selectUniqueOption(option, element);
    }
  }

}
