<mat-optgroup
    #optionGroup
    *ngFor="let item of groups"
    class="w-[185px] block"
    [label]="item.label"
    (click)="toggleExpand(item.label); $event.stopPropagation();"
    [matMenuTriggerFor]="optionMenu"
    (menuOpened)="manageOptions()"
>

    <button 
        mat-button
        class="absolute right-0"
    >
        <mat-icon *ngIf="isExpanded()[item.label]">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="!isExpanded()[item.label]">arrow_right</mat-icon>
    </button>

    <mat-menu 
        #optionMenu="matMenu"
        class="absolute -top-12 left-[185px]"
        (closed)="toggleExpand(item.label)"
    >
        <green-option-group 
            *ngIf="item.groups && item.groups.length; else options"
            [groups]="item.groups" 
            (selectedOptions)="selectedOptions.emit($event)"
            [multiple]="multiple"
            [selected]="selectedElements()">
        </green-option-group>

        <ng-template #options >
            <mat-option
                #option
                class="option mr-1 dark:!text-white w-[185px]"
                name="type"
                *ngFor="let element of item.options; let i=index"
                (click)="selectOption(option, element, item.label, $event)"
                [value]="element.name"
            >
            <green-truncated-text
                [value]="element.name"
                [maxLength]="30"
                [matTooltip]="element.name.length > 30 ? element.name : ''"/>
            </mat-option>
        </ng-template>
    </mat-menu>
</mat-optgroup>